import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'
import { Button } from '../Buttons'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`

const Grid = styled.div`
	width: 85%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	grid-gap: 22px;
	padding: 40px 10px;

	@media (max-width: 991.98px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 20px 15px;
	}
`

const Subtitle = styled.h3`
	margin: 0;
	position: relative;
	font-size: 40px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};

		@media (max-width: 767.98px) {
			height: 4px;
		}
	}

	@media (max-width: 767.98px) {
		font-size: 28px;
	}
`

const Text = styled.div`
	padding-top: 40px;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-block-start: 0;
	margin-block-end: 0;
	@media (max-width: 991.98px) {
		line-height: 1.4;
		font-size: 15px;
		padding-top: 20px;
	}

	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		margin: 0;
		color: #302a38;
	}
`

const Image = styled.img`
	max-width: 647px;
	width: 100%;
`

const TextWrapper = styled.div`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		max-width: 647px;
		width: 100%;
		margin: 10px auto;
	}

	color: ${theme('colors.gray66')};
`

const SecaoImage = styled.div`
	width: 95%;
	display: block;
	margin: 0 0 auto auto;
	@media (max-width: 767.98px) {
		width: 100%;
		margin: 0;
	}
`

const ButtonContainer = styled.a`
	display: flex;
	justify-content: flex-start;
	button {
		margin: 0 !important;
	}
	text-decoration: none;
`

const ButtonText = styled.span`
	/* font-family: FSLola; */
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
`

const ButtonStyled = styled(Button)`
	width: auto;
	height: auto;
	padding: 15px 35px;
`
export default function SecaoLandingPage({ cosmicjsLandingPages }) {
	return (
		<Wrapper>
			<Grid>
				<TextWrapper>
					<Subtitle>{cosmicjsLandingPages.metadata.secao1.titulo}</Subtitle>
					<Text dangerouslySetInnerHTML={{ __html: cosmicjsLandingPages.metadata.secao1.descricao }} />
					{process.env.GATSBY_COSMIC_BUCKET === 'amaissp' && (
						<ButtonContainer target='_blank' href={cosmicjsLandingPages.metadata.secao1.link_botao}>
							<ButtonStyled id='button-mobile-attendance'>
								<ButtonText>{cosmicjsLandingPages.metadata.secao1.texto_botao}</ButtonText>
							</ButtonStyled>
						</ButtonContainer>
					)}
				</TextWrapper>
				<SecaoImage>
					<Image
						src={imgixUrlOptimizerAuto(cosmicjsLandingPages.metadata.secao1.imagem.imgix_url, 647, 388)}
						alt='Atendimento móvel alt text'
						width='647px'
					/>
				</SecaoImage>
			</Grid>
		</Wrapper>
	)
}
